export enum MessageStatus {
  ERROR = 0,
  PENDING = 1,
  SENT = 2,
  ON_DEVICE = 3,
  READ = 4,
  PLAYED = 5,
  RECEIVED = 6,
  INTERNAL_MESSAGE = 7,
}
