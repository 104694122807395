export const playNotification = () => {
  if (!document.hasFocus()) {
    const audio = document.getElementById(
      'notificationAudio',
    ) as HTMLAudioElement
    try {
      audio.play()
    } catch {}
  }
}

export const showNotification = (props: { title: string; body: string }) => {
  const { title, body } = props
  const { isSupported, show } = useWebNotification({
    title,
    body,
    dir: 'auto',
  })

  if (isSupported.value) show()
}
