import _ from 'lodash'

export function removeEmptyKeys(obj: any) {
  // Iterate over the object's keys
  for (let key in obj) {
    // Check if the value of the key is an empty string
    if (_.isEmpty(obj[key])) {
      // Remove the key from the object
      delete obj[key]
    }
  }
  // Return the modified object
  return obj
}

export function getDialogParentContainer(
  event: Event,
): { parentContainer: string } | undefined {
  // Verifica o elemento mais próximo do evento que é um <dialog>
  const dialogElement = (event.target as HTMLElement)?.closest('dialog')

  // Retorna o objeto com o ID do dialog, se existir
  if (dialogElement && dialogElement.id) {
    return { parentContainer: `#${dialogElement.id}` }
  }

  // Retorna undefined se nenhum <dialog> com ID for encontrado
  return undefined
}
