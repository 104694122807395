export async function editContact(
  event: Event,
  props: {
    form: { name: string; number: string }
    activeChatStore: any
    contactStore: any
  },
) {
  const toastOptions = getDialogParentContainer(event)
  const { form, activeChatStore, contactStore } = props

  try {
    await window.axios.patch(
      route('v2.contact.updateWithValidation'),
      {
        name: form.name,
        number: form.number,
        contactId: activeChatStore.getId,
        instanceId: activeChatStore.currentContact.instanceId,
      },
      {
        'axios-retry': {
          retries: 0,
        },
      },
    )

    $toast.success('Contato atualizado com sucesso!', toastOptions)

    contactStore.updateContact({
      contactId: activeChatStore.getId,
      name: form.name,
      number: form.number,
    })
  } catch (e: any) {
    $toast.success(
      e.response.data.error ||
        'Houve um erro durante a edição do contato, tente novamente!',
      toastOptions,
    )
  }
}

export async function appendTags(
  event: Event,
  props: {
    selectedTags: number[]
    store: any
  },
) {
  const toastOptions = getDialogParentContainer(event)
  const { selectedTags, store } = props

  if (!selectedTags.length) {
    $toast.error('Nenhuma etiqueta foi selecionada!', toastOptions)
    return
  }

  try {
    const { data: response } = await window.axios.patch(
      route('chat.contact.assignTags'),
      {
        contactId: store.currentContact.props.contact.id,
        tags: selectedTags,
      },
    )

    store.currentContact.props.contact.tags = response.tags
    store.toggleTagAssign()

    return true
  } catch {
    $toast.error('Houve um erro, tente novamente!', toastOptions)
  }
}

export async function removeTag(props: { indexTag: number; store: any }) {
  const { indexTag, store } = props

  if (!store.currentContact.tags) return
  const relationId = store.currentContact.tags[indexTag]?.id

  if (relationId) {
    try {
      await window.axios.delete(
        route('chat.contact.removeTag', {
          _query: {
            contact_id: store.currentContact.contactId,
            relation_id: relationId,
          },
        }),
      )

      store.currentContact.tags?.splice(indexTag, 1)
    } catch {
      $toast.error('Houve um erro, tente novamente!')
    }
  }
}

export async function getComments(props: { contactId: number }) {
  const { contactId: contact_id } = props

  try {
    const { data } = await window.axios.get<IPChat.ContactComment[]>(
      route('chat.contact.getComments', {
        _query: { contact_id },
      }),
    )

    return data
  } catch (e) {
    $toast.error('Houve um erro ao carregar as observações, tente novamente!', {
      parentContainer: '#elContactCommentsModal',
    })
  }
}

export async function saveComment(props: {
  contactId: number
  comment: string
}) {
  const { contactId, comment } = props

  try {
    await window.axios.post(
      route('chat.contact.addComment'),
      {
        contactId,
        comment,
      },
      {
        'axios-retry': {
          retries: 0,
        },
      },
    )
    $toast.success('Observação salva com sucesso')
  } catch (e: any) {
    $toast.error(
      String(Object.values(e.response.data.errors)[0]) ??
        'Houve um erro, tente novamente!',
    )
  }
}

export async function saveMessageSchedule(props: {
  contactId: number
  instanceId: number
  message: Ref<string>
}) {
  const { messageSchedule } = await import('../Scripts/Forms')
  const { contactId, instanceId, message } = props

  messageSchedule
    .transform((data) => ({
      ...data,
      message: message.value,
    }))
    .post(
      route('message.schedule.store', {
        contact: contactId,
        _query: {
          instance: instanceId,
        },
      }),
      {
        onSuccess: () => {
          $toast.success('Agendamento realizado com sucesso.', {
            parentContainer: '#elMessageScheduling',
          })

          messageSchedule.reset()
          messageSchedule.clearErrors()
          message.value = ''
        },
        onError: (response) => {
          $toast.error(
            Object.values(response)[0] ??
              'Não foi possível agendar a mensagem, tente novamente!',
            {
              parentContainer: '#elMessageScheduling',
            },
          )
        },
      },
    )
}

export async function updateMessageSchedule(props: {
  contactId: number
  scheduleId: number
  instanceId: number
  message: Ref<string>
}) {
  const { messageSchedule } = await import('../Scripts/Forms')
  const { contactId, instanceId, scheduleId, message } = props

  messageSchedule
    .transform((data) => ({
      ...data,
      message: message.value,
    }))
    .post(
      route('message.schedule.update', {
        contact: contactId,
        scheduleId,
        _query: {
          instance: instanceId,
        },
      }),
      {
        onSuccess: () => {
          $toast.success('Agendamento editado com sucesso.', {
            parentContainer: '#elMessageSchedulingEdit',
          })
        },
        onError: (response) => {
          $toast.error(
            Object.values(response)[0] ??
              'Não foi possível editar o agendamento, tente novamente!',
            {
              parentContainer: '#elMessageSchedulingEdit',
            },
          )
        },
      },
    )
}

export async function destroySchedule(event: Event, scheduleId: number) {
  const toastOptions = getDialogParentContainer(event)

  try {
    await window.axios.delete(
      route('message.schedule.destroy', { schedule: scheduleId }),
    )

    $toast.success('Agendamento excluído com sucesso', toastOptions)
  } catch {
    $toast.error(
      'Não foi possível excluir o agendamento, tente novamente!',
      toastOptions,
    )
  }
}

export async function resendFailedMessage(messageKey: string) {
  await window.axios.post(
    route('v2.conversation.resendMessage', { _query: { messageKey } }),
  )
}
